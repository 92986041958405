/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-unknown-property */
import * as React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";
import { BsArrowDownShort } from "react-icons/bs";
import {
  Tabs,
  Tab,
  Nav,
  Accordion,
  useAccordionButton,
  AccordionContext,
} from "react-bootstrap";
import classNames from "classnames";
import { Link, graphql } from "gatsby";
import { heroBg } from "../lib/helpers";

function CustomToggle({ children, eventKey, callback }) {
  const { activeEventKey } = React.useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <div className="accordion-header" role="tab">
      <button
        className={classNames("accordion-button", {
          collapsed: !isCurrentEventKey,
        })}
        type="button"
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    </div>
  );
}

export const query = graphql`
  query servicesQuery {
    page: sanitySitePage(slug: { current: { eq: "services" } }) {
      id
      mainImage {
        ...SanityImage
        alt
      }
      cardImage {
        ...SanityImage
        alt
      }
      title
      description
      slug {
        current
      }
    }
  }
`;

// markup
const ServicePage = ({ data, location }) => {
  const { page = {} } = data;
  return (
    <Layout>
      <SEO {...page} location={location} />
      <div class="main">
        <div
          class="heroBanner-innerPage service-banner"
          style={heroBg(page.mainImage)}
        >
          <section class="banner-caption  d-flex">
            <div class="container d-flex align-content-center flex-wrap">
              <div class="banner-caption_inner  ">
                <h1>What We Do </h1>
                <p class="paragraph-large breadcrumb ">
                  <Link to="/">Home</Link>{" "}
                  <span class="icon-arrow_forward"></span>
                  {page.title}
                </p>
              </div>
            </div>
          </section>
        </div>
        <section class="sercvice-base-section pt-5 pb-3">
          <div class="container">
            <div class="heading-n-description ">
              <h2>We do Salesforce and Heroku consulting.</h2>
              <p class="paragraph-large">
                We specialize in providing top-quality Salesforce and Heroku
                consulting services. Our cloud-based consulting company offers a
                range of cost-effective software engineering services designed
                to help you get the most out of your Salesforce and Heroku
                ecosystems. Whether you need assistance with Salesforce
                customization, Heroku app development, integration, or support,
                our team of experts is here to help. With years of experience in
                these platforms, we have the skills and knowledge needed to
                deliver best-in-class solutions that meet your unique business
                needs. So if you're looking for a trusted partner to help you
                succeed with Salesforce and Heroku, look no further than
                CloudAlgo.
              </p>
            </div>
          </div>
        </section>
        <section class=" grid-outer even-order">
          <div class="container">
            <h3 class="d-flex align-items-center with-arrow font32 orange">
              <i style={{ top: "-3px" }}>
                <BsArrowDownShort />
              </i>
              Our Services
            </h3>

            <section class="services">
              <div class="services-card">
                <div class="services-card--image d-flex align-items-center">
                  <StaticImage src="./../images/services-img1.jpg" alt="" />
                </div>
                <div class="services-card--details d-flex align-items-center">
                  <div>
                    <h2 class="orange"> Consulting</h2>
                    <p>
                      We are your go-to partner for all your Salesforce
                      consulting needs. Our team has extensive knowledge and
                      experience with standard Salesforce functionality and
                      features, as well as a deep understanding of the unique
                      challenges facing businesses in various industries. We
                      work closely with you to develop customized solutions that
                      are tailored to your specific business processes and
                      needs, helping you get the most out of your Salesforce
                      ecosystem. Whether you need help with Salesforce
                      customization, integration, or support, our team of
                      experts is here to help you every step of the way.{" "}
                    </p>
                  </div>
                </div>
              </div>

              <div class="services-card">
                <div class="services-card--image d-flex align-items-center">
                  <StaticImage src="./../images/services-img2.jpg" alt="" />
                </div>
                <div class="services-card--details d-flex align-items-center">
                  <div>
                    <h2 class="orange">Product Development</h2>
                    <p>
                      We specialize in helping turn your unique business ideas
                      into innovative products. Our experienced product
                      development team is dedicated to delivering top-quality
                      solutions that meet your specific needs and requirements.
                      Whether you're looking to build products based on
                      Salesforce platform, Polyglot technologies, or a
                      combination of both, we have the expertise and knowledge
                      needed to bring your ideas to life. From initial concept
                      to final delivery, we work closely with you to ensure that
                      your product meets all your expectations, and that it
                      delivers real value to your business.
                    </p>
                  </div>
                </div>
              </div>

              <div class="services-card">
                <div class="services-card--image d-flex align-items-center">
                  <StaticImage src="./../images/services-img3.jpg" alt="" />
                </div>
                <div class="services-card--details d-flex align-items-center">
                  <div>
                    <h2 class="orange">Support & Managed Services</h2>
                    <p>
                      We specialize in providing end-to-end Salesforce
                      platform-based application maintenance and support
                      services that are designed to help you maximize your
                      investment and achieve your business objectives. Our team
                      of experts has extensive experience in managing and
                      maintaining Salesforce solutions of all sizes and
                      complexities, and we offer a wide range of support
                      services to help you ensure that your applications are
                      running smoothly and efficiently. From enhancements and
                      transitions to post-production support and everything in
                      between, we have the expertise and knowledge needed to
                      provide you with top-quality support and managed services.
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>

        <section class="process-section grey-bg2 section-padding--vertical ">
          <div class="container vtabs">
            <div class="heading-n-description text-center pb-4">
              <h2>Our Development Process</h2>
            </div>
            <div class="row">
              <Tab.Container id="left-tabs" defaultActiveKey="discovery">
                <div class="col-md-4 pe-md-0">
                  <Nav as="ul" className="nav nav-tabs left-tabs">
                    <Nav.Item as="li">
                      <Nav.Link
                        as="div"
                        eventKey="discovery"
                        className="tab-clickable"
                      >
                        Discovery
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li">
                      <Nav.Link
                        as="div"
                        eventKey="define"
                        className="tab-clickable"
                      >
                        Define
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li">
                      <Nav.Link
                        as="div"
                        eventKey="designArchitect"
                        className="tab-clickable"
                      >
                        Design & Architect
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li">
                      <Nav.Link
                        as="div"
                        eventKey="developTest"
                        className="tab-clickable"
                      >
                        Develop & Test
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li">
                      <Nav.Link
                        as="div"
                        eventKey="deploy"
                        className="tab-clickable"
                      >
                        Deploy
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li">
                      <Nav.Link
                        as="div"
                        eventKey="supportEnhancement"
                        className="tab-clickable"
                      >
                        Support & Enhancement
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>

                <div class="col-md-8 ps-md-0">
                  <div className="height-100p right-tab-detail">
                    <Accordion
                      defaultActiveKey="0"
                      className="tab-content accordion p-0 height-100p"
                    >
                      <Tab.Pane
                        eventKey="discovery"
                        className="discovery-bg accordion-item"
                      >
                        <CustomToggle eventKey="0">Discovery</CustomToggle>
                        <Accordion.Collapse
                          as="article"
                          className="accordion-body"
                          eventKey="0"
                        >
                          <section>
                            At the beginning of a software development project,
                            we conduct client meetings to gain a clear
                            understanding of the business goals, target
                            audience, and the vision for the product, idea or
                            problem.
                          </section>
                        </Accordion.Collapse>
                      </Tab.Pane>
                      <Tab.Pane
                        eventKey="define"
                        className="discovery-bg accordion-item"
                      >
                        <CustomToggle eventKey="1">Define</CustomToggle>
                        <Accordion.Collapse
                          as="article"
                          className="accordion-body"
                          eventKey="1"
                        >
                          <section>
                            During the Define stage of the development process,
                            we conduct extensive research and sketch out
                            preliminary concepts. We then propose a detailed
                            requirement specification document that includes
                            pricing, standard regulatory and governance terms
                            and conditions, a commercial offer, and an
                            agreement.
                          </section>
                        </Accordion.Collapse>
                      </Tab.Pane>
                      <Tab.Pane
                        eventKey="designArchitect"
                        className="discovery-bg accordion-item"
                      >
                        <CustomToggle eventKey="2">
                          Design & Architect
                        </CustomToggle>
                        <Accordion.Collapse
                          as="article"
                          className="accordion-body"
                          eventKey="2"
                        >
                          <section>
                            During the Design & Architect stage, we work with
                            the client to create a detailed plan for
                            development, including milestones and identifying
                            potential challenges. We create wireframes, mockups,
                            and prototypes to visualize the product. We also
                            consider scalability, security, and performance.
                            After defining the functional requirements, we
                            divide the work into smaller sprints, delivering
                            working increments of the product. This approach
                            ensures clients are continuously updated and allows
                            us to make changes based on feedback.
                          </section>
                        </Accordion.Collapse>
                      </Tab.Pane>
                      <Tab.Pane
                        eventKey="developTest"
                        className="discovery-bg accordion-item"
                      >
                        <CustomToggle eventKey="3">Develop & Test</CustomToggle>
                        <Accordion.Collapse
                          as="article"
                          className="accordion-body"
                          eventKey="3"
                        >
                          <section>
                            We utilize Agile Development Process to iteratively
                            execute the future state solution. Our team works in
                            a war room environment to develop code and convert
                            screens into real working solutions. This stage is
                            closely tied with a release and sprint plan, with
                            retrospectives conducted at the end of each sprint
                            to collect feedback and improve our process. Our
                            team executes test plans in parallel while
                            developing to ensure bug-free delivery of the
                            solution that meets expectations.
                          </section>
                        </Accordion.Collapse>
                      </Tab.Pane>
                      <Tab.Pane
                        eventKey="deploy"
                        className="discovery-bg accordion-item"
                      >
                        <CustomToggle eventKey="4">Deploy</CustomToggle>
                        <Accordion.Collapse
                          as="article"
                          className="accordion-body"
                          eventKey="4"
                        >
                          <section>
                            After completion of all Sprints in a release, we
                            deploy our work onto the User Acceptance Testing
                            environment (UAT) where actual users test the
                            solution and after successful testing, bug fixing
                            and enhancements we deploy it into the Production
                            environment and make sure it is ready for actual
                            users to use with real data.
                          </section>
                        </Accordion.Collapse>
                      </Tab.Pane>
                      <Tab.Pane
                        eventKey="supportEnhancement"
                        className="discovery-bg accordion-item"
                      >
                        <CustomToggle eventKey="5">
                          Support & Enhancement
                        </CustomToggle>
                        <Accordion.Collapse
                          as="article"
                          className="accordion-body"
                          eventKey="5"
                        >
                          <section>
                            We don't leave our customers alone after deployment,
                            we are always ready to help them with ongoing
                            support and enhancements
                          </section>
                        </Accordion.Collapse>
                      </Tab.Pane>
                    </Accordion>
                  </div>
                </div>
              </Tab.Container>
            </div>
          </div>
        </section>
        <section class="salesforce-services section-padding--vertical">
          <div class="container">
            <div class="row">
              <div class="col-md-6">
                <StaticImage
                  src="./../images/salesforce.png"
                  alt="Salesforce Service Image"
                />
              </div>
              <div class="col-md-6">
                <div class="heading-n-description text-left">
                  <h2>Our Saleforce Services</h2>
                </div>

                <ul class="salesforce-services--list list-unstyled row">
                  <li class="col-md-6">Salesforce Consulting</li>
                  <li class="col-md-6">Salesforce Integration</li>
                  <li class="col-md-6">Sales Cloud</li>
                  <li class="col-md-6">Salesforce Support</li>
                  <li class="col-md-6">Service Cloud</li>
                  <li class="col-md-6">Salesforce Communities</li>
                  <li class="col-md-6">Force.com App Development</li>
                  <li class="col-md-6">Salesforce Lightning</li>
                  <li class="col-md-6">Heroku and Heroku Connect</li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        {/*         <section class="logos-section grey-bg">
          <div class="container  ">
            <div class="justify-content-between d-flex align-items-center flex-wrap text-center">
              <div class="c-logo">
                <StaticImage src="./../images/c-logo1.png" alt="clogo" />
              </div>
              <div class="c-logo">
                <StaticImage src="./../images/c-logo2.png" alt="clogo" />
              </div>
              <div class="c-logo">
                <StaticImage src="./../images/c-logo3.png" alt="clogo" />
              </div>
              <div class="c-logo">
                <StaticImage src="./../images/c-logo4.png" alt="clogo" />
              </div>
            </div>
          </div>
        </section> */}
      </div>
    </Layout>
  );
};

export default ServicePage;
